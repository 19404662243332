@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

.warningBox {
  background-color: rgba(233,227,113,1);
  padding: 20px;
}

.dottedList > li {
  color: rgba(255,159,0,1);
  list-style-type: none;
  position: relative;    /* It is required for setting position to absolute in the next rule. */
}

.dottedList > li:not(:first-child) {
  margin-top: 15px;
}

.dottedList > li::before {
  content: '\2022';      /* Unicode for • character */
  position: absolute;
  left: -0.8em;          /* Adjust this value so that it appears where you want. */
  top: 2px;
  font-size: 36px;      /* Adjust this value so that it appears what size you want. */
}

.bracketedList {
  counter-reset: list;
}
.bracketedList > li {
  list-style: none;
  position: relative;    /* It is required for setting position to absolute in the next rule. */
}
.bracketedList > li::before {
  content: counter(list, lower-alpha) ") ";
  counter-increment: list;
  position: absolute;
  left: -20px;          /* Adjust this value so that it appears where you want. */
}

.ol-mouse-position {
  color: white;
  font-weight: bold;
  right: 70px;
}

.ol-zoom {
  top: initial;
  left: initial;
  right: 10px;
  bottom: 10px;
}

.reset-button {
  top: initial;
  left: initial;
  right: 10px;
  bottom: 70px;
}

.tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
}
.tooltip-measure {
  opacity: 1;
  font-weight: bold;
}
.tooltip-static {
  background-color: #ffcc33;
  color: black;
  border: 1px solid white;
}
.tooltip-measure:before,
.tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}
.tooltip-static:before {
  border-top-color: #ffcc33;
}

.ol-control.ol-layerswitcher {
  top: 10px;
  right: 10px;
}

.ol-control.ol-layerswitcher-popup {
  bottom: initial;
  left: initial;
  right: 10px;
  top: 0px;
}

.ol-layerswitcher .panel li label {
  display: flex;
  align-items: center;
}

.ol-layerswitcher .layerswitcher-opacity {
  display: none;
}

.ol-layerswitcher > button {
  z-index: inherit;
}
.ol-overlay {
  background-color: rgba(0, 0, 0, 0.9);
}

.draw-polygon {
  right: .5em;
  bottom: 80px;
}

.ol-touch .draw-polygon {
  bottom: 80px;

}.layers {
   right: .5em;
   top: 30px;
 }

.ol-touch .layers {
  bottom: 30px;
}

.ol-zoom-in:focus {

}

.ol-button i
{	color: inherit;
}
.ol-button.ol-active button
{	background: rgba(60, 136, 0, 0.7)

}

.info-header {
  font-size: 16px;
  font-weight: 500;
}

.video-block {
  width: 80%;
  max-width: 640px;
  margin: 0 auto;
  padding-top: 30px;
}

.video-container {
  position:relative;
  padding-bottom:56.25%;
  padding-top:30px;
  height:0;
  overflow:hidden;
}

.video-container iframe, .video-container object, .video-container embed {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}
